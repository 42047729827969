import BaseBean from "@/utils/BaseBean";

export interface IOrderListDataObj {
    utilInst:OrderListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class OrderListUtil extends BaseBean{
    public dataObj:IOrderListDataObj

    constructor(proxy:any,dataObj:IOrderListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}